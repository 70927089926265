import { Link, Stack } from '@mui/material';
import { useActions } from '@src/overmind';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { openDialog } = useActions().ui;
  const { t } = useTranslation();

  const handleClick = () => {
    openDialog({ type: 'privacy' });
  };

  return (
    <Stack justifyContent="center" alignItems="center" py={2}>
      <Link
        onPointerDown={handleClick}
        sx={{ cursor: 'pointer', textTransform: 'capitalize' }}
        variant="caption"
      >
        {t('LWC.commons.privacy')}
      </Link>
    </Stack>
  );
};
